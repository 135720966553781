export const getPrice = price =>
  Intl.NumberFormat(undefined, {
    currency: "GBP",
    minimumFractionDigits: 2,
    style: "currency",
  }).format(parseFloat(price ? price : 0))

export const totalItems = lineItems =>
  lineItems.reduce((a, b) => a + b.quantity, 0)

export const titleize = sentence => {
  if (!sentence.split) return sentence

  const _titleizeWord = string =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  const result = []

  sentence.split("-").forEach(w => {
    result.push(_titleizeWord(w))
  })

  return result.join(" ")
}

export const getTodaysDate = () => {
  const today = new Date()

  return (
    today.getFullYear() +
    "-" +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + today.getDate()).slice(-2)
  )
}

const utils = {
  getPrice,
  totalItems,
  getTodaysDate,
  titleize,
}

export default utils
