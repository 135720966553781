import React from "react"
import { IoMenuOutline, IoClose } from "react-icons/io5"

const BurgerButton = ({ onClick, isActive }) => (
  <button
    onClick={onClick}
    className="inline-flex items-center justify-center transition-colors p-3 hover:text-orange focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
    aria-expanded="false"
  >
    <span className="sr-only">Open main menu</span>

    <IoMenuOutline className={`${isActive ? "hidden" : "block"} h-6 w-6`} />
    <IoClose className={`${isActive ? "block" : "hidden"} h-6 w-6`} />
  </button>
)

export default BurgerButton
