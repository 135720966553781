import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { IoLogoFacebook, IoLogoInstagram } from "react-icons/io5"

const SocialNav = ({ ...otherProps }) => (
  <nav {...otherProps}>
    <OutboundLink
      href="https://www.facebook.com/heartbeeflowers/"
      target="_blank"
      title="HeartBee Flowers Facebook"
      rel="noopener noreferrer"
      className="flex items-center justify-center h-12 w-12 text-base"
    >
      <p className="sr-only">Facebook</p>
      <IoLogoFacebook className="w-8 h-8" />
    </OutboundLink>

    <OutboundLink
      href="https://www.instagram.com/heartbeeflowers/"
      target="_blank"
      title="HeartBee Flowers Instagram"
      rel="noopener noreferrer"
      className="flex items-center justify-center h-12 w-12 text-base"
    >
      <p className="sr-only">Instagram</p>
      <IoLogoInstagram className="w-8 h-8" />
    </OutboundLink>
  </nav>
)

export default SocialNav
