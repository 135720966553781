export const cartReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_CHECKOUT":
      return { ...state, checkout: action.checkout, isAdding: false }

    case "TOGGLE_CART_PANEL":
      return { ...state, panelOpen: action.panelOpen }

    case "TOGGLE_INFO_MODAL":
      return { ...state, infoModalOpen: action.infoModalOpen }

    case "TOGGLE_INFO_CLOSED":
      return { ...state, infoClosed: action.infoClosed }

    default:
      return state
  }
}
