import React from "react"
import PropTypes from "prop-types"

import Header from "~/components/header"
import Footer from "~/components/footer"
import CartPanel from "~/components/cart-panel"
import CartLink from "~/components/cart-link"

import ContextProvider from "~/provider/context-provider"

const Layout = ({ location, children }) => {
  return (
    <ContextProvider>
      <div className="app-container min-h-screen flex flex-col">
        <CartLink isHome={location.pathname === "/"} />

        <div className=" py-4 text-center bg-orange-700">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 h-full">
            <p className="text-lg text-white">
              The shop is currently closed as all efforts are now focused on
              HeartBee Flower Farm.
            </p>
          </div>
        </div>

        {location.pathname !== "/" && <Header location={location} />}
        <main className="site-layout flex-1">{children}</main>
        <Footer />
        <CartPanel location={location} />
      </div>
    </ContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
