import React from "react"

const Container = ({ children, classes = [] }) => {
  const componentClasses = [
    "container mx-auto py-6 sm:py-10 lg:py-10 xl:py-20 px-4 sm:px-6",
    ...classes,
  ].join(" ")

  return <div className={componentClasses}>{children}</div>
}

export default Container
