import React, { useContext, useState, useEffect } from "react"
import { IoBagHandleOutline, IoBagHandle } from "react-icons/io5"
import { Link } from "gatsby"

import { totalItems } from "~/utils"
import StoreContext from "~/context/store-context"

const CartLink = ({ isHome = false }) => {
  const {
    cart: { checkout },
  } = useContext(StoreContext)

  const [cartQuantity, setCartQuantity] = useState(
    totalItems(checkout ? checkout.lineItems : [])
  )

  useEffect(() => {
    setCartQuantity(totalItems(checkout ? checkout.lineItems : []))
  }, [checkout])

  return (
    <Link
      className={`${!isHome ? "sm:hidden" : ""
        } bounce fixed z-50 top-6 right-6 p-3 bg-white rounded-full text-orange hover:text-orange-dark transition-colors`}
      activeClassName="text-orange font-medium"
      to="/cart"
    >
      <span className="sr-only">Your Cart</span>
      {cartQuantity === 0 && <IoBagHandleOutline className="h-6 w-6" />}
      {cartQuantity > 0 && <IoBagHandle className="h-6 w-6" />}

      {cartQuantity !== 0 && (
        <div className="absolute bg-pink text-gray-800 -top-2 -right-2 h-4 w-4 rounded-full text-sm font-medium flex items-center justify-center p-3">
          {cartQuantity}
        </div>
      )}
    </Link>
  )
}

export default CartLink
