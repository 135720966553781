import React, { useState, useContext, useEffect, useRef } from "react"
import { IoBagHandleOutline, IoBagHandle } from "react-icons/io5"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import StoreContext from "~/context/store-context"
import { useToggleCartPanel } from "~/provider/context-provider"

import { totalItems } from "~/utils"
import useLogo from "~/hooks/use-logo"
import BurgerButton from "~/components/burger-button"

const NavLink = ({ to, text, partiallyActive = false }) => (
  <Link
    className="header__link relative transition-colors hover:text-orange"
    activeClassName="text-orange font-bold header__link--active"
    to={to}
    partiallyActive={partiallyActive}
  >
    {text}
  </Link>
)

const MobileNavLink = ({ to, text, partiallyActive = false }) => (
  <Link
    className="relative transition-colors hover:text-orange"
    activeClassName="text-orange font-bold header__link--active"
    to={to}
    partiallyActive={partiallyActive}
  >
    {text}
  </Link>
)

const Header = ({ location }) => {
  const logoImage = useLogo()
  const [isActive, toggleIsActive] = useState(false)
  const toggleCartPanel = useToggleCartPanel()
  const myRef = useRef({ location: null })

  const {
    cart: { checkout },
  } = useContext(StoreContext)

  const [cartQuantity, setCartQuantity] = useState(
    totalItems(checkout ? checkout.lineItems : [])
  )

  useEffect(() => {
    setCartQuantity(totalItems(checkout ? checkout.lineItems : []))
  }, [checkout])

  useEffect(() => {
    if (!myRef.current.location) myRef.current.location = location
    else if (myRef.current.location !== location) {
      if (isActive) toggleIsActive(false)
      myRef.current.location = location
    }
  }, [isActive, location])

  const onMenuClicked = () => {
    toggleIsActive(!isActive)
  }

  const openCart = e => {
    e.preventDefault()
    toggleCartPanel()
  }

  return (
    <header className="bg-white relative h-24 lg:h-36">
      <div className="container mx-auto px-6 lg:px-8 h-full">
        <div className="fixed top-6 left-6 flex items-center sm:hidden z-50 bg-white rounded-full">
          <BurgerButton onClick={onMenuClicked} isActive={isActive} />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-6 lg:grid-cols-12 gap-6 lg:gap-10 h-full">
          <Link
            to="/"
            className="flex items-center justify-center md:col-span-2 md:mr-auto lg:col-span-5 xl:col-start-2"
          >
            <GatsbyImage
              image={logoImage}
              alt="HeartBee Flowers - Winchester"
              className="md:mr-3 w-16"
            />
            <div className="hidden md:block w-auto font-title font-semibold text-black text-2xl lg:text-3xl">
              HeartBee Flowers
            </div>
          </Link>

          <div
            className="hidden sm:block sm:col-span-5 sm
          :col-start-2 md:col-start-4 md:col-span-4 lg:col-span-5 lg:col-start-8 xl:col-span-4 xl:col-start-8"
          >
            <div className="flex justify-between items-center h-full max-w-sm ml-auto">
              <NavLink to="/" text="Home" />
              <NavLink to="/about" text="About" />
              <NavLink to="/shop" text="Shop" partiallyActive />
              <NavLink to="/contact" text="Contact" />

              <Link
                className="bounce relative text-gray-800 transition-colors h-6 w-6"
                activeClassName="text-orange font-bold"
                to="/cart"
                onClick={openCart}
              >
                <span className="sr-only">Your Cart</span>
                {cartQuantity === 0 && (
                  <IoBagHandleOutline className="h-6 w-6" />
                )}
                {cartQuantity > 0 && <IoBagHandle className="h-6 w-6" />}
                {cartQuantity !== 0 && (
                  <div className="absolute bg-pink -top-4 -right-4 h-4 w-4 rounded-full text-sm font-bold flex items-center justify-center p-3">
                    {cartQuantity}
                  </div>
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${
          isActive ? "block h-full fixed inset-0 z-40" : "hidden sm:hidden"
        }`}
      >
        <div className="bg-white h-full">
          <div className="h-24 mb-5 flex items-center justify-center">
            <Link to="/" className="flex items-center justify-center w-auto">
              <GatsbyImage
                image={logoImage}
                alt="HeartBee Flowers - Winchester"
                className="md:mr-3 w-16"
              />
            </Link>
          </div>

          <div className="flex flex-col p-6 justify-center items-center space-y-5">
            <MobileNavLink to="/" text="Home" />
            <MobileNavLink to="/about" text="About" />
            <MobileNavLink to="/shop" text="Shop" />
            <MobileNavLink to="/contact" text="Contact" />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
