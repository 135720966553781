import React, { useContext } from "react"
import { IoCloseOutline } from "react-icons/io5"

import StoreContext from "~/context/store-context"
import { useRemoveLineItems } from "~/provider/context-provider"
import { getPrice } from "~/utils"

const LineItem = ({ item }) => {
  const removeLineItem = useRemoveLineItems()

  const {
    cart: { checkout },
  } = useContext(StoreContext)

  const handleRemoveItem = () => {
    if (
      typeof window !== "undefined" &&
      process.env.NODE_ENV === "production"
    ) {
      window.gtag("event", "remove_from_cart", {
        currency: "GBP",
        items: [
          {
            item_id: item.variant.product.id,
            item_name: item.title,
            item_variant: item.variant.title,
            price: parseFloat(item.variant.priceV2.amount),
            currency: "GBP",
            quantity: item.quantity,
          },
        ],
        value: parseFloat(item.variant.priceV2.amount),
      })
    }

    removeLineItem(checkout.id, [item.id])
  }

  const variantTitle = item =>
    item.variant.title !== "Default Title" ? item.variant.title : null

  return (
    <div className="flex p-4 border-b border-orange relative">
      <div
        className="w-24 h-24 bg-cover hidden md:block"
        style={{
          backgroundImage: `url(${item.variant.image.src})`,
          backgroundPosition: "center",
        }}
      />

      <div className="flex-1 px-3 pl-0 md:pl-3 bg-white rounded rounded-l-none space-y-3">
        <div className="">
          <h4 className="text-lg font-semibold">{item.title}</h4>
          <h5>{variantTitle(item)}</h5>
        </div>

        <div className="flex">
          <p className="text-base mb-2">Qty: {item.quantity} </p>
          <p className="ml-5 text-base">
            {getPrice(item.variant.priceV2.amount * item.quantity)}
          </p>
        </div>
      </div>

      <button
        type="button"
        title="remove from cart"
        className="bounce absolute p-1 text-gray-600 rounded-full h-10 w-10 flex justify-center items-center flex-none hover:text-orange top-0 right-0"
        onClick={handleRemoveItem}
      >
        <IoCloseOutline className="h-6 w-6" />
      </button>
    </div>
  )
}

export default LineItem
