import { graphql, useStaticQuery } from "gatsby"

const useLogo = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulAsset(title: { eq: "Logo Image" }) {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  `)

  return data.contentfulAsset.gatsbyImageData
}

export default useLogo
