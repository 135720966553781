import { graphql, useStaticQuery } from "gatsby"

const usePolicies = () => {
  const data = useStaticQuery(graphql`
    query {
      policies: allContentfulPagePolicyPage(sort: {order: ASC, fields: handle}) {
        edges {
          node {
            id
            pageTitle
            handle
          }
        }
      }
    }
  `)

  return data.policies.edges.map(({ node }) => node)
}

export default usePolicies
