import React from "react"

const PaymentIcons = () => {
  return (
    <div className="flex flex-wrap justify-center items-center">
      <div className="ml-1 mr-1 mb-2 md:mb-0">
        <svg
          className="icon is-medium"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          viewBox="0 0 38 24"
          width="38"
          height="24"
          aria-labelledby="pi-american_express"
        >
          <title id="pi-american_express">American Express</title>
          <path
            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
            fill="#000"
            opacity=".07"
          ></path>
          <path
            d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
            fill="#002663"
          ></path>
          <path
            d="M8.971 10.268l.774 1.876H8.203l.768-1.876zm16.075.078h-2.977v.827h2.929v1.239h-2.923v.922h2.977v.739l2.077-2.245-2.077-2.34-.006.858zm-14.063-2.34h3.995l.887 1.935L16.687 8h10.37l1.078 1.19L29.25 8h4.763l-3.519 3.852 3.483 3.828h-4.834l-1.078-1.19-1.125 1.19H10.03l-.494-1.19H8.406l-.495 1.19H4L7.286 8h3.43l.267.006zm8.663 1.078h-2.239l-1.5 3.536-1.625-3.536H12.06v4.81l-2.06-4.81H8.007l-2.382 5.512H7.18l.494-1.19h2.596l.494 1.19h2.72v-3.935l1.751 3.941h1.19l1.74-3.929v3.93h1.458l.024-5.52zm9.34 2.768l2.531-2.768h-1.822l-1.601 1.726-1.548-1.726h-5.894v5.518h5.81l1.614-1.738 1.548 1.738h1.875l-2.512-2.75z"
            fill="#FFF"
          ></path>
        </svg>

        <span className="sr-only">American express</span>
      </div>

      <div className="ml-1 mr-1 mb-2 md:mb-0">
        <svg
          className="icon is-medium"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          x="0"
          y="0"
          width="38"
          height="24"
          viewBox="0 0 165.521 105.965"
          aria-labelledby="pi-apple_pay"
        >
          <title id="pi-apple_pay">Apple Pay</title>
          <path
            fill="#000"
            d="M150.698 0H14.823c-.566 0-1.133 0-1.698.003-.477.004-.953.009-1.43.022-1.039.028-2.087.09-3.113.274a10.51 10.51 0 0 0-2.958.975 9.932 9.932 0 0 0-4.35 4.35 10.463 10.463 0 0 0-.975 2.96C.113 9.611.052 10.658.024 11.696a70.22 70.22 0 0 0-.022 1.43C0 13.69 0 14.256 0 14.823v76.318c0 .567 0 1.132.002 1.699.003.476.009.953.022 1.43.028 1.036.09 2.084.275 3.11a10.46 10.46 0 0 0 .974 2.96 9.897 9.897 0 0 0 1.83 2.52 9.874 9.874 0 0 0 2.52 1.83c.947.483 1.917.79 2.96.977 1.025.183 2.073.245 3.112.273.477.011.953.017 1.43.02.565.004 1.132.004 1.698.004h135.875c.565 0 1.132 0 1.697-.004.476-.002.952-.009 1.431-.02 1.037-.028 2.085-.09 3.113-.273a10.478 10.478 0 0 0 2.958-.977 9.955 9.955 0 0 0 4.35-4.35c.483-.947.789-1.917.974-2.96.186-1.026.246-2.074.274-3.11.013-.477.02-.954.022-1.43.004-.567.004-1.132.004-1.699V14.824c0-.567 0-1.133-.004-1.699a63.067 63.067 0 0 0-.022-1.429c-.028-1.038-.088-2.085-.274-3.112a10.4 10.4 0 0 0-.974-2.96 9.94 9.94 0 0 0-4.35-4.35A10.52 10.52 0 0 0 156.939.3c-1.028-.185-2.076-.246-3.113-.274a71.417 71.417 0 0 0-1.431-.022C151.83 0 151.263 0 150.698 0z"
          ></path>
          <path
            fill="#FFF"
            d="M150.698 3.532l1.672.003c.452.003.905.008 1.36.02.793.022 1.719.065 2.583.22.75.135 1.38.34 1.984.648a6.392 6.392 0 0 1 2.804 2.807c.306.6.51 1.226.645 1.983.154.854.197 1.783.218 2.58.013.45.019.9.02 1.36.005.557.005 1.113.005 1.671v76.318c0 .558 0 1.114-.004 1.682-.002.45-.008.9-.02 1.35-.022.796-.065 1.725-.221 2.589a6.855 6.855 0 0 1-.645 1.975 6.397 6.397 0 0 1-2.808 2.807c-.6.306-1.228.511-1.971.645-.881.157-1.847.2-2.574.22-.457.01-.912.017-1.379.019-.555.004-1.113.004-1.669.004H14.801c-.55 0-1.1 0-1.66-.004a74.993 74.993 0 0 1-1.35-.018c-.744-.02-1.71-.064-2.584-.22a6.938 6.938 0 0 1-1.986-.65 6.337 6.337 0 0 1-1.622-1.18 6.355 6.355 0 0 1-1.178-1.623 6.935 6.935 0 0 1-.646-1.985c-.156-.863-.2-1.788-.22-2.578a66.088 66.088 0 0 1-.02-1.355l-.003-1.327V14.474l.002-1.325a66.7 66.7 0 0 1 .02-1.357c.022-.792.065-1.717.222-2.587a6.924 6.924 0 0 1 .646-1.981c.304-.598.7-1.144 1.18-1.623a6.386 6.386 0 0 1 1.624-1.18 6.96 6.96 0 0 1 1.98-.646c.865-.155 1.792-.198 2.586-.22.452-.012.905-.017 1.354-.02l1.677-.003h135.875"
          ></path>
          <g>
            <g>
              <path
                fill="#000"
                d="M43.508 35.77c1.404-1.755 2.356-4.112 2.105-6.52-2.054.102-4.56 1.355-6.012 3.112-1.303 1.504-2.456 3.959-2.156 6.266 2.306.2 4.61-1.152 6.063-2.858"
              ></path>
              <path
                fill="#000"
                d="M45.587 39.079c-3.35-.2-6.196 1.9-7.795 1.9-1.6 0-4.049-1.8-6.698-1.751-3.447.05-6.645 2-8.395 5.1-3.598 6.2-.95 15.4 2.55 20.45 1.699 2.5 3.747 5.25 6.445 5.151 2.55-.1 3.549-1.65 6.647-1.65 3.097 0 3.997 1.65 6.696 1.6 2.798-.05 4.548-2.5 6.247-5 1.95-2.85 2.747-5.6 2.797-5.75-.05-.05-5.396-2.101-5.446-8.251-.05-5.15 4.198-7.6 4.398-7.751-2.399-3.548-6.147-3.948-7.447-4.048"
              ></path>
            </g>
            <g>
              <path
                fill="#000"
                d="M78.973 32.11c7.278 0 12.347 5.017 12.347 12.321 0 7.33-5.173 12.373-12.529 12.373h-8.058V69.62h-5.822V32.11h14.062zm-8.24 19.807h6.68c5.07 0 7.954-2.729 7.954-7.46 0-4.73-2.885-7.434-7.928-7.434h-6.706v14.894z"
              ></path>
              <path
                fill="#000"
                d="M92.764 61.847c0-4.809 3.665-7.564 10.423-7.98l7.252-.442v-2.08c0-3.04-2.001-4.704-5.562-4.704-2.938 0-5.07 1.507-5.51 3.82h-5.252c.157-4.86 4.731-8.395 10.918-8.395 6.654 0 10.995 3.483 10.995 8.89v18.663h-5.38v-4.497h-.13c-1.534 2.937-4.914 4.782-8.579 4.782-5.406 0-9.175-3.222-9.175-8.057zm17.675-2.417v-2.106l-6.472.416c-3.64.234-5.536 1.585-5.536 3.95 0 2.288 1.975 3.77 5.068 3.77 3.95 0 6.94-2.522 6.94-6.03z"
              ></path>
              <path
                fill="#000"
                d="M120.975 79.652v-4.496c.364.051 1.247.103 1.715.103 2.573 0 4.029-1.09 4.913-3.899l.52-1.663-9.852-27.293h6.082l6.863 22.146h.13l6.862-22.146h5.927l-10.216 28.67c-2.34 6.577-5.017 8.735-10.683 8.735-.442 0-1.872-.052-2.261-.157z"
              ></path>
            </g>
          </g>
        </svg>

        <span className="sr-only">Apple pay</span>
      </div>

      <div className="ml-1 mr-1 mb-2 md:mb-0">
        <svg
          className="icon is-medium"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          viewBox="0 0 38 24"
          width="38"
          height="24"
          aria-labelledby="pi-google_pay"
        >
          <title id="pi-google_pay">Google Pay</title>
          <path
            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
            fill="#000"
            opacity=".07"
          ></path>
          <path
            d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
            fill="#FFF"
          ></path>
          <path
            d="M18.093 11.976v3.2h-1.018v-7.9h2.691a2.447 2.447 0 0 1 1.747.692 2.28 2.28 0 0 1 .11 3.224l-.11.116c-.47.447-1.098.69-1.747.674l-1.673-.006zm0-3.732v2.788h1.698c.377.012.741-.135 1.005-.404a1.391 1.391 0 0 0-1.005-2.354l-1.698-.03zm6.484 1.348c.65-.03 1.286.188 1.778.613.445.43.682 1.03.65 1.649v3.334h-.969v-.766h-.049a1.93 1.93 0 0 1-1.673.931 2.17 2.17 0 0 1-1.496-.533 1.667 1.667 0 0 1-.613-1.324 1.606 1.606 0 0 1 .613-1.336 2.746 2.746 0 0 1 1.698-.515c.517-.02 1.03.093 1.49.331v-.208a1.134 1.134 0 0 0-.417-.901 1.416 1.416 0 0 0-.98-.368 1.545 1.545 0 0 0-1.319.717l-.895-.564a2.488 2.488 0 0 1 2.182-1.06zM23.29 13.52a.79.79 0 0 0 .337.662c.223.176.5.269.785.263.429-.001.84-.17 1.146-.472.305-.286.478-.685.478-1.103a2.047 2.047 0 0 0-1.324-.374 1.716 1.716 0 0 0-1.03.294.883.883 0 0 0-.392.73zm9.286-3.75l-3.39 7.79h-1.048l1.281-2.728-2.224-5.062h1.103l1.612 3.885 1.569-3.885h1.097z"
            fill="#5F6368"
          ></path>
          <path
            d="M13.986 11.284c0-.308-.024-.616-.073-.92h-4.29v1.747h2.451a2.096 2.096 0 0 1-.9 1.373v1.134h1.464a4.433 4.433 0 0 0 1.348-3.334z"
            fill="#4285F4"
          ></path>
          <path
            d="M9.629 15.721a4.352 4.352 0 0 0 3.01-1.097l-1.466-1.14a2.752 2.752 0 0 1-4.094-1.44H5.577v1.17a4.53 4.53 0 0 0 4.052 2.507z"
            fill="#34A853"
          ></path>
          <path
            d="M7.079 12.05a2.709 2.709 0 0 1 0-1.735v-1.17H5.577a4.505 4.505 0 0 0 0 4.075l1.502-1.17z"
            fill="#FBBC04"
          ></path>
          <path
            d="M9.629 8.44a2.452 2.452 0 0 1 1.74.68l1.3-1.293a4.37 4.37 0 0 0-3.065-1.183 4.53 4.53 0 0 0-4.027 2.5l1.502 1.171a2.715 2.715 0 0 1 2.55-1.875z"
            fill="#EA4335"
          ></path>
        </svg>

        <span className="sr-only">Google pay</span>
      </div>

      <div className="ml-1 mr-1 mb-2 md:mb-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon is-medium"
          viewBox="0 0 38 24"
          width="38"
          height="24"
          role="img"
          aria-labelledby="pi-maestro"
        >
          <title id="pi-maestro">Maestro</title>
          <path
            opacity=".07"
            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
          ></path>
          <path
            fill="#fff"
            d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
          ></path>
          <circle fill="#EB001B" cx="15" cy="12" r="7"></circle>
          <circle fill="#00A2E5" cx="23" cy="12" r="7"></circle>
          <path
            fill="#7375CF"
            d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"
          ></path>
        </svg>

        <span className="sr-only">Maestro</span>
      </div>

      <div className="ml-1 mr-1 mb-2 md:mb-0">
        <svg
          className="icon is-medium"
          viewBox="0 0 38 24"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          width="38"
          height="24"
          aria-labelledby="pi-master"
        >
          <title id="pi-master">Mastercard</title>
          <path
            opacity=".07"
            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
          ></path>
          <path
            fill="#fff"
            d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
          ></path>
          <circle fill="#EB001B" cx="15" cy="12" r="7"></circle>
          <circle fill="#F79E1B" cx="23" cy="12" r="7"></circle>
          <path
            fill="#FF5F00"
            d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"
          ></path>
        </svg>

        <span className="sr-only">Master</span>
      </div>

      <div className="ml-1 mr-1 mb-2 md:mb-0">
        <svg
          className="icon is-medium"
          viewBox="0 0 38 24"
          xmlns="http://www.w3.org/2000/svg"
          width="38"
          height="24"
          role="img"
          aria-labelledby="pi-paypal"
        >
          <title id="pi-paypal">PayPal</title>
          <path
            opacity=".07"
            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
          ></path>
          <path
            fill="#fff"
            d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
          ></path>
          <path
            fill="#003087"
            d="M23.9 8.3c.2-1 0-1.7-.6-2.3-.6-.7-1.7-1-3.1-1h-4.1c-.3 0-.5.2-.6.5L14 15.6c0 .2.1.4.3.4H17l.4-3.4 1.8-2.2 4.7-2.1z"
          ></path>
          <path
            fill="#3086C8"
            d="M23.9 8.3l-.2.2c-.5 2.8-2.2 3.8-4.6 3.8H18c-.3 0-.5.2-.6.5l-.6 3.9-.2 1c0 .2.1.4.3.4H19c.3 0 .5-.2.5-.4v-.1l.4-2.4v-.1c0-.2.3-.4.5-.4h.3c2.1 0 3.7-.8 4.1-3.2.2-1 .1-1.8-.4-2.4-.1-.5-.3-.7-.5-.8z"
          ></path>
          <path
            fill="#012169"
            d="M23.3 8.1c-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.3-.1-.3-.1-.7-.1-1.1-.1h-3c-.1 0-.2 0-.2.1-.2.1-.3.2-.3.4l-.7 4.4v.1c0-.3.3-.5.6-.5h1.3c2.5 0 4.1-1 4.6-3.8v-.2c-.1-.1-.3-.2-.5-.2h-.1z"
          ></path>
        </svg>

        <span className="sr-only">Paypal</span>
      </div>

      <div className="ml-1 mr-1 mb-2 md:mb-0">
        <svg
          className="icon is-medium"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          viewBox="0 0 38 24"
          width="38"
          height="24"
          aria-labelledby="pi-shopify_pay"
        >
          <title id="pi-shopify_pay">Shopify Pay</title>
          <path
            d="M35.712 24H2.288A2.279 2.279 0 0 1 0 21.731V2.269A2.279 2.279 0 0 1 2.289 0H35.71A2.279 2.279 0 0 1 38 2.269v19.462A2.279 2.279 0 0 1 35.712 24"
            fill="#5C6AC4"
          ></path>
          <path
            d="M35.712 23.5H2.288A1.779 1.779 0 0 1 .5 21.731V2.269A1.78 1.78 0 0 1 2.289.5H35.71a1.78 1.78 0 0 1 1.789 1.769v19.462c0 .973-.797 1.769-1.788 1.769z"
            fill="none"
            stroke="#142688"
            strokeOpacity=".2"
          ></path>
          <path
            d="M12.892 7.934l-.835-.063-.614-.61a.128.128 0 0 0-.052-.031l-.395 9.12 3.118-.678-1.132-7.654a.1.1 0 0 0-.09-.084zm-2.81-.35v-.108c0-.33-.045-.597-.12-.808.297.037.494.374.62.76-.148.047-.317.1-.5.156zm-.653 3.295s-.346-.185-.77-.185c-.622 0-.653.39-.653.488 0 .537 1.398.742 1.398 2 0 .988-.627 1.625-1.473 1.625-1.014 0-1.534-.632-1.534-.632l.272-.897s.534.457.984.457a.397.397 0 0 0 .413-.4c0-.7-1.148-.731-1.148-1.882 0-.967.695-1.904 2.098-1.904.54 0 .807.155.807.155l-.394 1.175zm-.23-4.52a.3.3 0 0 1 .171.057c-.428.202-.888.71-1.081 1.723l-.817.254c.227-.774.766-2.035 1.727-2.035zm.533 1.276v.058l-1.034.32c.199-.768.572-1.14.898-1.28.082.207.136.503.136.902zm1.487-.402l-.31.095c-.185-.532-.51-1.02-1.085-1.02-.016 0-.032 0-.048.002-.163-.216-.365-.31-.54-.31C7.899 6 7.261 7.671 7.06 8.52l-.935.29c-.29.092-.3.1-.337.374C5.759 9.39 5 15.258 5 15.258l5.855 1.097.395-9.13a.32.32 0 0 0-.031.008zm7.394 4.348c.143.022.286.044.506.044.97 0 1.498-.727 1.498-1.443 0-.65-.43-.914-1.024-.914-.242 0-.43.01-.55.044l-.43 2.269zm-.848-3.36c.462-.11 1.157-.176 1.796-.176.616 0 1.376.11 1.894.518.44.341.694.87.694 1.498a2.71 2.71 0 0 1-.991 2.126c-.573.462-1.4.672-2.225.672-.21 0-.408-.022-.551-.034l-.518 2.688h-1.487l1.388-7.292zm7.715 2.985a1.485 1.485 0 0 0-.385-.044c-.991 0-1.663 1.421-1.663 2.358 0 .517.165.87.573.87.451 0 .969-.562 1.2-1.785l.276-1.399zm-.473 4.308c.01-.309.044-.65.066-1.014h-.034c-.473.837-1.112 1.124-1.674 1.124-.914 0-1.498-.727-1.498-1.796 0-1.762 1.09-3.822 3.646-3.822a5.94 5.94 0 0 1 1.664.253l-.573 2.875c-.132.683-.232 1.73-.22 2.38h-1.377zm4.357-5.247l.242 2.284c.067.54.11.925.132 1.277h.023c.12-.363.231-.705.451-1.289l.926-2.272h1.586l-1.862 3.848c-.66 1.366-1.3 2.368-1.994 3.018-.54.507-1.178.76-1.487.826l-.418-1.289a3.28 3.28 0 0 0 .859-.43 2.6 2.6 0 0 0 .826-.914c.044-.077.055-.143.033-.253l-.843-4.806h1.526z"
            fill="#fff"
          ></path>
        </svg>

        <span className="sr-only">Shopify pay</span>
      </div>

      <div className="ml-1 mr-1 mb-2 md:mb-0 md:mr-0">
        <svg
          className="icon is-medium"
          viewBox="0 0 38 24"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          width="38"
          height="24"
          aria-labelledby="pi-visa"
        >
          <title id="pi-visa">Visa</title>
          <path
            opacity=".07"
            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
          ></path>
          <path
            fill="#fff"
            d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
          ></path>
          <path
            d="M28.3 10.1H28c-.4 1-.7 1.5-1 3h1.9c-.3-1.5-.3-2.2-.6-3zm2.9 5.9h-1.7c-.1 0-.1 0-.2-.1l-.2-.9-.1-.2h-2.4c-.1 0-.2 0-.2.2l-.3.9c0 .1-.1.1-.1.1h-2.1l.2-.5L27 8.7c0-.5.3-.7.8-.7h1.5c.1 0 .2 0 .2.2l1.4 6.5c.1.4.2.7.2 1.1.1.1.1.1.1.2zm-13.4-.3l.4-1.8c.1 0 .2.1.2.1.7.3 1.4.5 2.1.4.2 0 .5-.1.7-.2.5-.2.5-.7.1-1.1-.2-.2-.5-.3-.8-.5-.4-.2-.8-.4-1.1-.7-1.2-1-.8-2.4-.1-3.1.6-.4.9-.8 1.7-.8 1.2 0 2.5 0 3.1.2h.1c-.1.6-.2 1.1-.4 1.7-.5-.2-1-.4-1.5-.4-.3 0-.6 0-.9.1-.2 0-.3.1-.4.2-.2.2-.2.5 0 .7l.5.4c.4.2.8.4 1.1.6.5.3 1 .8 1.1 1.4.2.9-.1 1.7-.9 2.3-.5.4-.7.6-1.4.6-1.4 0-2.5.1-3.4-.2-.1.2-.1.2-.2.1zm-3.5.3c.1-.7.1-.7.2-1 .5-2.2 1-4.5 1.4-6.7.1-.2.1-.3.3-.3H18c-.2 1.2-.4 2.1-.7 3.2-.3 1.5-.6 3-1 4.5 0 .2-.1.2-.3.2M5 8.2c0-.1.2-.2.3-.2h3.4c.5 0 .9.3 1 .8l.9 4.4c0 .1 0 .1.1.2 0-.1.1-.1.1-.1l2.1-5.1c-.1-.1 0-.2.1-.2h2.1c0 .1 0 .1-.1.2l-3.1 7.3c-.1.2-.1.3-.2.4-.1.1-.3 0-.5 0H9.7c-.1 0-.2 0-.2-.2L7.9 9.5c-.2-.2-.5-.5-.9-.6-.6-.3-1.7-.5-1.9-.5L5 8.2z"
            fill="#142688"
          ></path>
        </svg>

        <span className="sr-only">Visa</span>
      </div>
    </div>
  )
}

export default PaymentIcons
